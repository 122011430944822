import { render, staticRenderFns } from "./TreatsPlus.vue?vue&type=template&id=48a90f61&scoped=true"
import script from "./TreatsPlus.vue?vue&type=script&lang=js"
export * from "./TreatsPlus.vue?vue&type=script&lang=js"
import style0 from "./TreatsPlus.vue?vue&type=style&index=0&id=48a90f61&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a90f61",
  null
  
)

export default component.exports